<template>
  <div>
    <!-- Components -->
    <queryPfadaufgabe />
    <queryMcFrage />
    <darkBackground />
    <infoOverlay button="Lernspot verlassen" @buttonClicked="confirmCancelLernspot" index=1 />
    <infoOverlay :text="currentLernspot?.finishedText"  :json="true" button="Zurück zum Lernpfad" @buttonClicked="confirmFinishLernspot" index=2 denyClosing=true />
    <infoOverlay text="Du hast noch nicht alle Abschnitte abgeschlossen. Du kannst in der Übersicht sehen, welche Slides dir noch fehlen."  :json="true" button="Erst mal zurück zum Lernpfad" @buttonClicked="confirmFinishLernspot" index=3 />
    <infoOverlay :text="solutionText" index=4 />
    <slideOverview :lernspot=currentLernspot?.Bezeichnung :slides=currentLernspot?.slides :currentSlideId=currentSlideId @changeSlide="changeSlide" />
    <listPicker :currentQuestionId=selectedLernspotId listName="lernpfade"/>
    <fullscreenVideo />
    <!-- Components -->

    <!-- Nav Bar Overlay-->
    <div class="w-full md:w-24 md:h-screen bg-new-yellow md:bg-transparent fixed flex flex-col md:flex-col justify-between md:justify-between items-center z-10 top-0 md:left-0 font-visby text-new-black" :class="{'pt-safe' : parseInt(sat) !== 0}">
      <div class="w-full flex md:h-screen flex-row md:flex-col p-5 justify-between items-center">

        <div class="z-10 cursor-pointer md:order-last" @click="cancelLernspot" >
          <backSVG color='red' class="md:hidden flex-shrink-0 md:mb-5 md:h-10 md:w-10 md:p-2" />
          <backBoldSVG color='red' class="hidden md:block flex-shrink-0 md:mb-5 md:h-10 md:w-10 md:p-2" />
        </div>

        <div v-if="currentLernspot?.slides[currentSlide]?.Typ === 'Inhalt'" class="md:hidden flex-grow text-center px-5 text-lg truncate">{{currentLernspot?.Bezeichnung}}</div>
        <div v-else class="md:hidden flex-grow text-center px-5 text-lg truncate">{{currentLernspot.slides.length === currentSlide  ? 'Abschlussaufgabe' : 'Zwischenaufgabe'}}</div>

        <div class="flex flex-row md:flex-col md:h-1/2 md:justify-between">
          <starSVG :full="includedInList ? true : false" class=" flex-shrink-0 mr-5 md:mr-0 md:p-2 md:w-12 md:h-12" @click="openListPicker" />
          <gridSVG @click="openOverview" class="flex-shrink-0 md:order-first md:p-2 md:right-0 md:w-12 md:h-12 md:mt-5 cursor-pointer"/>
        </div>

      </div>
      <div class="md:fixed md:top-0 md:left-0 relative w-full md:w-screen md:pl-24">
        <div class="w-full h-1.5 bg-new-lightGrey2">
          <div class="absolute h-full bg-new-orange"
          :class="[calculateWidth(currentLernspot.slides.length, currentSlide + 1), calculateWidth(currentLernspot.slides.length, currentSlide + 1) === 'w-full' ? 'rounded-none' : 'rounded-tr-full rounded-br-full']"
          :style="{'background-color': currentCourseColor2}"></div>
        </div>
      </div>
    </div>
    <!-- Nav Bar Overlay-->

    <!-- Content Wrapper -->
    <div class="w-full min-h-screen md:max-h-screen md:overflow-hidden p-5 pb-28 pt-10 md:p-0 md:mt-0 md:pl-24 bg-new-yellow font-visby text-new-black select-none md:mb-0 md:flex md:flex-row">

      <!-- Desktop Left Side -->
      <div class="md:w-1/2 md:px-10 py-5 md:flex md:flex-col md:h-screen">

        <!-- Scrollable -->
        <div class="md:flex-grow md:overflow-y-auto scrollbar mt-6" :class="{'pt-safe' : parseInt(sat) !== 0}">

          <!-- Slide Content -->
          <div class="italic text-sm">Abschnitt {{currentSlide + 1}} von {{currentLernspot?.slides.length}}</div>
          <div class="font-bold mt-1" v-if="currentLernspot?.slides[currentSlide]?.Typ === 'Pfadaufgabe' && currentPfadaufgabe?.Aufgabenbeschreibung">{{currentPfadaufgabe.Aufgabenbeschreibung}}</div>


          <!-- CONTENT -->
          <div v-if="currentLernspot?.slides[currentSlide]?.Typ === 'Inhalt'">
            <div v-if="currentLernspot?.slides[currentSlide]?.content">
              <tipTap :content="currentLernspot.slides[currentSlide].content" />
            </div>

            <!-- Scrollspace Video On -->
            <div v-if="showOverlay === 'fullscreenVideo'" class="h-50"></div>
          </div>

          <!-- TASKS -->
          <div v-else>

          <!-- Pfadaufgabe Lückentext etc -->
          <div v-if="taskType === 'ComponentAufgabenLueckentext'">
            <tipTap :content="currentPfadaufgabe?.Aufgabentyp[0].content" :selectedAnswers=selectedAnswers :extraLineHeight="true" type="lückentext" />
          </div>

          <!-- MC Frage -->
          <div v-if="(taskType === 'ComponentAufgabenMcFrage' || contentType === 'MC_Frage') && currentMcFrage?.Frage">
            <tipTap :content="currentMcFrage.Frage" />
            <div v-if="currentMcFrage?.Antwort" class="w-full bg-white rounded-xl shadow-new border border-new-yellow2 overflow-hidden">
              <div v-for="(antwort, index) in currentMcFrage?.Antwort" :key="antwort" class="w-full p-3 flex flex-row justify-between items-center font-visby cursor-pointer" @click="addAnswer(antwort?.id)"
              :class="{'border-b-2 border-new-yellow' : index < currentMcFrage?.Antwort?.length -1 ? true : false,
              'bg-new-black' : selectedAnswers.includes(antwort.id)}">
              <tipTap :content="antwort?.Antwort" displayType="clean" :color="selectedAnswers.includes(antwort.id) ? 'white' : 'black'"/>
              </div>
            </div>
          </div>

          <!-- Vokabeltrainer -->
          <div v-if="taskType === 'ComponentAufgabenVokabel'">
            <tipTap :content="currentPfadaufgabe?.Aufgabentyp[0].content" />
          </div>

          <!-- Begriffspaare -->
          <div v-if="taskType === 'ComponentAufgabenBegriffspaare' && possibleAnswers && result !== 'success'" class="flex flex-wrap w-auto" ref="pairAnswers">
            <div v-for="answer in splitPairAnswers(possibleAnswers)" :key=answer id="pairAnswer" :data-answer="answer" class="bg-white rounded-xl shadow-new p-3 border border-new-yellow2 mt-3 mr-3"
            :class="{'bg-new-black text-white' : answer === focusedAnswer }"
            @touchstart.passive="pairDragStart"
            @touchend.passive="pairDragEnd"
            @touchmove.prevent="pairDrag"
            @mousedown.passive="pairDragStart"
            @mouseup.passive="pairDragEnd"
            @mousemove.prevent="pairDrag">
              {{answer}}
            </div>
          </div>
          <div v-else-if="taskType === 'ComponentAufgabenBegriffspaare' && possibleAnswers" class="mt-10 text-center p-5">Du hast alle Begriffe richtig zugeordnet.</div>

          <!-- Listen -->
          <div v-if="taskType === 'ComponentAufgabenListenzuordnung'">
            <div v-if="currentPfadaufgabe?.Aufgabentyp[0].Liste" class="sticky top-20 z-10 flex flex-row justify-between items-center mt-5 bg-white rounded-xl shadow-new p-3 px-5" id="liste">
              <div class="break-words hyphen w-5/12">{{ currentPfadaufgabe?.Aufgabentyp[0].Liste[0].Listenname }}</div>
              <dragSVG class="flex-shrink-0" />
              <div class="break-words hyphen w-5/12 text-right">{{ currentPfadaufgabe?.Aufgabentyp[0].Liste[1].Listenname }}</div>
            </div>

            <div v-if="possibleAnswers" class="flex flex-col justify-center items-center mt-5" ref="answers">
              <div v-for="answer in possibleAnswers" :key="answer" id="answer" :data-answer="answer" data-moved="0" data-maxleft="0" data-maxright="0"
                class="bg-white rounded-xl shadow-new p-3 border border-new-yellow2 mt-2 mb-2 w-2/3 text-center flex flex-row justify-between items-center"
                :class="{'ring-2 ring-new-orange' : answer === focusedAnswer}"
                @click="focusedAnswer = answer"
                @touchstart.passive="answerDragStart"
                @touchend.passive="answerDragEnd"
                @touchmove="answerDrag"
                @mousedown.passive="answerDragStart"
                @mouseup.passive="answerDragEnd"
                @mousemove="answerDrag">
                <chevronSVG class="transform rotate-180 cursor-pointer" @click="moveLeft($event)"/>
                <div>{{ answer }}</div>
                <chevronSVG class="cursor-pointer" @click="moveRight($event)"/>
              </div>
            </div>
          </div>

          <!-- Scrollspace -->
          <div v-if="possibleAnswers" class="flex flex-col flex-wrap p-3 opacity-0 max-h-52 w-full max-w-screen overflow-hidden">
            <div v-for="answer in possibleAnswers" :key=answer >
              <div>{{answer}}</div>
            </div>
          </div>
          <div v-else class="h-36"></div>

          </div>
        </div>
      </div>

      <!-- Desktop Right Side -->
      <div class="hidden md:w-1/2 md:px-10 py-5 md:flex md:flex-col md:h-screen bg-new-yellow3"></div>

    </div>
    <!-- Content Wrapper -->

    <!-- Globales Bild Desktop -->
    <div v-if="currentLernspot?.GlobalesBild?.data && currentLernspot?.slides[currentSlide]?.Typ === 'Inhalt'" class="hidden md:absolute md:top-5 md:right-5 md:flex flex-shrink-0 flex-row justify-center items-center w-14 h-14 bg-white rounded-full border border-new-black shadow-lg m-5 pointer-events-auto cursor-pointer" @click="openGlobalImage()">
      <imageSVG />
    </div>

    <!-- Globales Video Desktop -->
    <div v-else-if="!currentLernspot?.GlobalesBild?.data && currentLernspot?.globalesVideo && currentLernspot?.slides[currentSlide]?.Typ === 'Inhalt'" class="hidden md:absolute md:top-5 md:right-5 md:flex flex-shrink-0 flex-row justify-center items-center w-14 h-14 bg-white rounded-full border border-new-black shadow-lg m-5 pointer-events-auto cursor-pointer" @click="openGlobalVideo()">
      <videoSVG />
    </div>

    <!-- Buttons -->
    <div v-if="currentLernspot?.slides[currentSlide]?.Typ === 'Inhalt'" class="fixed bottom-0 w-full md:pl-24 flex flex-col md:flex-row justify-end items-end pointer-events-none">
      <div v-if="currentLernspot?.GlobalesBild?.data" class="md:hidden flex flex-shrink-0 flex-row justify-center items-center w-14 h-14 bg-white rounded-full border border-new-black shadow-lg m-5 pointer-events-auto" @click="openGlobalImage()">
        <imageSVG />
      </div>
      <div v-else-if="currentLernspot?.globalesVideo" class="md:hidden flex flex-shrink-0 flex-row justify-center items-center w-14 h-14 bg-white rounded-full border border-new-black shadow-lg m-5 pointer-events-auto" @click="openGlobalVideo()">
        <videoSVG />
      </div>
      <div class="hidden md:block md:w-1/2 pointer-events-none"></div>
      <div class="pb-5 min-h-28 w-full md:w-1/2 p-5 md:px-10 bg-white shadow-top md:shadow-none md:bg-transparent pointer-events-auto flex flex-row">
        <customButton v-if="currentSlide > 0" label="zurück" :inactive=false @click="previousSlide" class="mr-2" />
        <customButton :label="currentSlide < currentLernspot.slides.length -1 ? 'weiter' : 'beenden'" :inactive=false @click="nextSlide" :loading=savingProgress />
      </div>
    </div>

    <!-- Pfadaufgaben Nav -->
    <div v-else class="fixed bottom-0 md:right-0 md:top-12 w-full md:w-1/2  pointer-events-none font-visby text-new-black">

      <!-- Check Answer -->
      <div v-if="
      ((taskType === 'ComponentAufgabenMcFrage' || contentType === 'MC_Frage') && maxAnswers === selectedAnswers?.length) ||
      (taskType === 'ComponentAufgabenVokabel' && inputText !== '') ||
      (taskType === 'ComponentAufgabenBegriffspaare' && selectedAnswers?.length === 2) ||
      (taskType === 'ComponentAufgabenListenzuordnung' && maxAnswers === selectedAnswers?.length) ||
      (taskType === 'ComponentAufgabenLueckentext' && !selectedAnswers.includes('OPTION'))"
      class="md:absolute md:bottom-0 md:pb-5 md:w-full md:px-12 md:pl-24">
        <div v-if="!result" class="w-full h-auto bg-new-black text-white text-center text-lg p-5 pointer-events-auto cursor-pointer md:rounded-lg md:shadow-lg" @click="preCheckAnswer()">Antwort prüfen</div>
        <div v-else-if="result === 'continue'" class="w-full h-auto text-new-black bg-green-200 text-center text-lg p-5 pointer-events-auto cursor-pointer md:rounded-lg md:shadow-lg" @click="hidePairs()">Super, das war richtig!</div>
        <div v-else-if="result === 'success'" class="w-full h-auto text-new-black bg-green-200 text-center text-lg p-5 pointer-events-auto cursor-pointer md:rounded-lg md:shadow-lg" @click="nextSlide()">Das war richtig! Zum nächsten Abschnitt</div>
        <div v-else-if="result === 'fail'" class="w-full h-auto text-new-black bg-red-200 text-center text-lg p-5 pointer-events-auto cursor-pointer md:rounded-lg md:shadow-lg" @click="refresh()">Das war leider falsch.</div>
      </div>

      <!-- NAV-->
      <div class="w-full bg-white md:bg-transparent shadow-top md:shadow-none pointer-events-auto md:px-12 md:pl-24">
        <div class="flex flex-row justify-between md:justify-start items-center p-3 border-new-black border-b md:border-none">
          <div class="flex flex-row justify-between flex-grow items-center md:bg-white md:p-3 md:rounded-xl md:mr-3 cursor-pointer select-none">
            <div v-if="currentSlide > 0" class="mx-2 md:mx-0 bg-new-black text-white px-3 py-1 rounded-lg" @click="previousSlide">Zurück</div>
            <div v-if="currentSlide < currentLernspot.slides.length -1 && (trials < 2 || taskType === 'ComponentAufgabenMcFrage' || this.contentType === 'MC_Frage')" class="mr-2 md:mx-0 bg-new-black text-white px-3 py-1 rounded-lg" @click="skipSlide">{{ currentSlideProgress.includes(this.currentSlideId) ? 'Weiter' : 'Überspringen' }}</div>
            <div v-if="trials >= 2 && (taskType !== 'ComponentAufgabenMcFrage' && this.contentType !== 'MC_Frage')" class="mr-2 md:mx-0 bg-green-500 text-white px-3 py-1 rounded-lg" @click="showSolution()">Lösung zeigen</div>
          </div>
          <div class="flex flex-row items-center">
            <div class="md:p-3 md:rounded-xl md:mr-3 mr-2 cursor-pointer select-none bg-new-black px-3 py-1 rounded-lg" @click="refresh()">
              <reloadSVG class="text-white" />
            </div>
            <div v-if="taskType !== 'ComponentAufgabenListenzuordnung' && taskType !== 'ComponentAufgabenBegriffspaare'" class=" md:p-3 md:rounded-xl cursor-pointer select-none bg-new-black px-3 py-1 rounded-lg" @click="removeLastAnswer()">
              <removeSVG class="text-white" />
            </div>
          </div>
        </div>

        <!-- Space MC Fragen-->
        <div v-if="taskType === 'ComponentAufgabenMcFrage' || contentType === 'MC_Frage'" class="w-full h-24 flex flex-row justify-center items-center text-center px-10">
          Wähle {{maxAnswers !== 1 ? maxAnswers : 'eine'}} {{maxAnswers !== 1 ? 'Antworten' : 'Antwort'}}
        </div>

        <!-- Info Drag -->
        <div v-if="taskType === 'ComponentAufgabenListenzuordnung'" class="w-full h-24 flex flex-row justify-center items-center text-center px-10">
          Verschiebe die Antwort unter die richtige Liste.
        </div>

        <!-- Begriffspaare-->
        <div v-else-if="taskType === 'ComponentAufgabenBegriffspaare'" class="w-full h-24 flex flex-row justify-center items-center text-center px-10">
          Verschiebe die passenden Begriffe übereinander.
        </div>

        <!-- Input field-->
        <div v-else-if="taskType === 'ComponentAufgabenVokabel'" class="p-5 pb-24">
          <input v-model="inputText" placeholder="Begriff eingeben" class="w-full appearance-none outline-none border border-new-black py-3 placeholder-new-midGrey text-center">
        </div>

        <div v-else-if="taskType === 'ComponentAufgabenLueckentext'">
          <div v-if="possibleAnswers" class="flex flex-row flex-wrap p-3 pb-8 md:w-10/12 max-h-52 overflow-y-auto">
            <div v-for="answer in possibleAnswers" :key=answer >
              <div class="text-white bg-new-black rounded-lg p-3 mr-2 mb-2 cursor-pointer select-none" :class="{'opacity-40' : selectedAnswers.includes(answer)}" @click="addAnswer(answer)">{{answer}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// SVG
import gridSVG from '../../../assets/svg/grid.vue';
import backSVG from '../../../assets/svg/back.vue';
import backBoldSVG from '../../../assets/svg/backBold.vue';
import starSVG from '../../../assets/svg/star.vue';
import reloadSVG from '../../../assets/svg/reload.vue';
import removeSVG from '../../../assets/svg/remove.vue';
import dragSVG from '../../../assets/svg/drag.vue';
import chevronSVG from '../../../assets/svg/chevron.vue';
import imageSVG from '../../../assets/svg/image.vue';
import videoSVG from '../../../assets/svg/video.vue';

// Mixins
import detectNotch from '../../../mixins/detectNotch.vue';
import scrollTo from '../../../mixins/scrollTo.vue';
import fadeInOut from '../../../mixins/animations/fadeInOut.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';
import progressBarWidth from '../../../mixins/progressBarWidth.vue';
import mobile from '../../../mixins/mobile.vue';
import pfadaufgabenFunctions from '../../../mixins/pfadaufgabenFunctions.vue';
import colors from '../../../mixins/colors.vue';

// Components
import customButton from '../../../symbols/customButton.vue';
import darkBackground from '../../../symbols/darkBackground.vue';
import infoOverlay from '../../../symbols/infoOverlay.vue';
import listPicker from '../../../symbols/listPicker.vue';
import tipTap from '../../../symbols/tipTap.vue';
import slideOverview from '../components/slideOverview.vue';
import fullscreenVideo from '../../../symbols/fullscreenVideo.vue';

// Strapi
import queryPfadaufgabe from '../strapi/queryPfadaufgabe.vue';
import queryMcFrage from '../strapi/queryMcFrage.vue';

export default {
  name: 'lernspot',
  mixins: [detectNotch, fadeInOut, iosVibrations, scrollTo, progressBarWidth, mobile, pfadaufgabenFunctions, colors],
  components: {
    chevronSVG,
    gridSVG,
    dragSVG,
    backSVG,
    backBoldSVG,
    starSVG,
    customButton,
    darkBackground,
    infoOverlay,
    listPicker,
    slideOverview,
    tipTap,
    queryPfadaufgabe,
    queryMcFrage,
    reloadSVG,
    removeSVG,
    imageSVG,
    videoSVG,
    fullscreenVideo,
  },

  data() {
    return {
      trials: 0,
      solutionText: '',
      initialSlide: 0,
      currentSlide: 0,
      openNavIcon: false,
      isChecking: false,
    };
  },

  computed: {
    lists() {
      return this.$store.state?.profile?.me?.userData?.data?.attributes?.myLists;
    },
    includedInList() {
      let included = false;
      this.lists.forEach((list) => {
        if (list.lernpfade.ids.includes(this.selectedLernspotId)) {
          included = true;
        }
      });
      return included;
    },
    selectedLernspotId() {
      return this.$store.state.learning.selectedLernspotId;
    },
    currentLernspot() {
      return this.$store.getters.currentLernspot;
    },
    currentPfadaufgabe() {
      return this.$store.state?.learning?.selectedPfadaufgabe?.pfadaufgaben?.data?.attributes;
    },
    currentMcFrage() {
      return this.$store.state?.learning?.selectedMcFrage?.mcFragen?.data?.attributes;
    },
    currentMcFrageId() {
      return this.$store.state?.learning?.selectedMcFrage?.mcFragen?.data?.id;
    },
    possibleAnswers() {
      if (!this.leftAnswers.length > 0) {
        const pfadaufgabe = this.$store.state.learning.selectedPfadaufgabe?.pfadaufgaben?.data?.attributes?.Aufgabentyp[0];
        return this.$store.getters.possibleAnswers(pfadaufgabe);
      }
      return this.leftAnswers;
    },
    currentSlideId() {
      return this.currentLernspot?.slides[this.currentSlide]?.id;
    },
    nextSlideId() {
      return this.currentLernspot?.slides[this.currentSlide + 1]?.id;
    },
    taskType() {
      // eslint-disable-next-line no-underscore-dangle
      return this.currentPfadaufgabe?.Aufgabentyp[0].__typename;
    },
    contentType() {
      return this.currentLernspot?.slides[this.currentSlide]?.Typ;
    },
    maxAnswers() {
      if (this.currentLernspot?.slides[this.currentSlide]?.Typ === 'Pfadaufgabe' || this.contentType === 'MC_Frage') {
        if (this.taskType === 'ComponentAufgabenLueckentext') {
          return this.currentPfadaufgabe.Aufgabentyp[0].content.split('</s>').length - 1;
        }
        if (this.taskType === 'ComponentAufgabenBegriffspaare') {
          return 2;
        }
        if (this.taskType === 'ComponentAufgabenListenzuordnung') {
          let count = 0;
          this.currentPfadaufgabe.Aufgabentyp[0].Liste.forEach((liste) => {
            const newList = liste.Answers.split(';');
            count += newList.length;
          });
          return count;
        }
        if (this.taskType === 'ComponentAufgabenMcFrage' || this.contentType === 'MC_Frage') {
          let count = 0;
          if (this.currentMcFrage?.Antwort) {
            this.currentMcFrage.Antwort.forEach((antwort) => {
              if (antwort.Richtig) {
                count += 1;
              }
            });
          }
          return count;
        }
        // return this.currentPfadaufgabe?.Antworten.split(';').length;
      }
      return null;
    },
    selectedLernpfadId() {
      return this.$store.state.learning.selectedLernpfadId;
    },
    lernpfade() {
      return this.$store.state.profile.me.userData.data.attributes.lernpfadProgress.lernpfade;
    },
    lernpfadIndex() {
      return this?.lernpfade.findIndex((lernpfad) => lernpfad.id === this?.selectedLernpfadId);
    },
    lernspotIndex() {
      return this.lernpfade[this?.lernpfadIndex]?.lernspots.findIndex((lernspot) => lernspot.id === this?.selectedLernspotId);
    },
    currentSlideProgress() {
      if (this.lernpfadIndex === -1 || this.lernspotIndex === -1) {
        return [];
      }
      return this?.lernpfade[this.lernpfadIndex]?.lernspots[this.lernspotIndex]?.slides;
    },
    currentMaxSlideId() {
      return this?.lernpfade[this.lernpfadIndex]?.lernspots[this.lernspotIndex]?.currentMaxSlideId;
    },
    validateAnswers() {
      return this.$store?.state?.validateAnswers;
    },
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
  },

  watch: {
    currentSlide() {
      this.trials = 0;
      this.initPfadaufgabe();
      if (this?.taskType === 'ComponentAufgabenMcFrage') {
        this.$store.commit('setSelectedMcFrageId', this.currentPfadaufgabe?.Aufgabentyp[0]?.mc_frage?.data?.id);
      }
    },

  },

  mounted() {
    this.scrollToTop();
    this.preLoadImages();
    this.initPfadaufgabe();

    // make swipes possible
    window.onpopstate = (event) => {
      if (event?.state?.currentSlide) {
        this.currentSlide = event.state.currentSlide;
      } else {
        this.currentSlide = this.initialSlide;
      }
    };

    if (this.currentMaxSlideId && this.currentMaxSlideId !== this.currentSlideId) {
      const index = this.currentLernspot?.slides.findIndex((slide) => slide.id === this.currentMaxSlideId);
      if (index !== -1) {
        this.changeSlide(index);
        this.initialSlide = index;
      }
    }
  },

  methods: {

    showSolution() {
      if (this.taskType === 'ComponentAufgabenBegriffspaare') this.solutionBegriffspaare();
      if (this.taskType === 'ComponentAufgabenListenzuordnung') this.solutionListenzuordnung();
      if (this.taskType === 'ComponentAufgabenVokabel') this.solutionVokabel();
      if (this.taskType === 'ComponentAufgabenLueckentext') this.solutionLueckentext();
    },

    solutionBegriffspaare() {
      this.solutionText = 'Folgende Begriffe gehören zusammen: ';
      this.currentPfadaufgabe.Aufgabentyp[0].Paar.forEach((paar, index) => {
        this.solutionText += `${paar.begriff1} & ${paar.begriff2}${index < this.currentPfadaufgabe.Aufgabentyp[0].Paar.length - 1 ? ',' : ''} `;
      });
      this.$store.commit('toggleInfoOverlay', { value: true, index: 4 });
    },

    solutionListenzuordnung() {
      this.solutionText = `Auf die Seite von ${this.currentPfadaufgabe.Aufgabentyp[0].Liste[0].Listenname} gehören die Begriffe: ${this.currentPfadaufgabe.Aufgabentyp[0].Liste[0].Answers}. `;
      this.solutionText += `Auf die Seite von ${this.currentPfadaufgabe.Aufgabentyp[0].Liste[1].Listenname} gehören die Begriffe: ${this.currentPfadaufgabe.Aufgabentyp[0].Liste[1].Answers}.`;
      this.$store.commit('toggleInfoOverlay', { value: true, index: 4 });
    },

    solutionVokabel() {
      this.solutionText = `Gib einen der folgenden Begriffe ein: ${this.currentPfadaufgabe.Aufgabentyp[0].answer}`;
      this.$store.commit('toggleInfoOverlay', { value: true, index: 4 });
    },

    solutionLueckentext() {
      const validate = [];
      const splittedAnswers = this.currentPfadaufgabe.Aufgabentyp[0].content.split('</s>');
      splittedAnswers.pop();
      splittedAnswers.forEach((answer) => {
        const splitted = answer.split('<s>').pop();
        const parsed = new DOMParser().parseFromString(splitted, 'text/html');
        validate.push(parsed.documentElement.textContent);
      });
      this.solutionText = 'Diese Begriffe müssen in dieser Reihenfolge eingesetzt werden: ';
      validate.forEach((answer, index) => {
        this.solutionText += `${answer}${index < validate.length - 1 ? ',' : ''}  `;
      });
      this.$store.commit('toggleInfoOverlay', { value: true, index: 4 });
    },

    openGlobalImage() {
      this.$store.commit('setGlobalImageUrl', this.currentLernspot?.GlobalesBild?.data?.attributes?.url);
      this.$store.commit('showOverlay', 'fullscreenImage');
    },

    openGlobalVideo() {
      this.$store.commit('setGlobalVideoUrl', this.currentLernspot?.globalesVideo);
      this.$store.commit('showBlankOverlay', 'fullscreenVideo');
    },

    preLoadImages() {
      if (this.currentLernspot) {
        this.currentLernspot.slides.forEach((slide) => {
          const dom = new DOMParser().parseFromString(slide.content, 'text/html');
          const images = [...dom.getElementsByTagName('img')];
          if (images.length > 0) {
            images.forEach((image) => {
              if (image?.src) {
                const preloadedImage = new Image();
                preloadedImage.src = image.src;
              }
            });
          }
        });
      }
    },

    initPfadaufgabe() {
      const typ = this.currentLernspot?.slides[this.currentSlide]?.Typ;
      if (typ === 'Pfadaufgabe') {
        // only
        this.$store.commit('setSelectedMcFrageId', null);
        this.$store.commit('setSelectedPfadaufgabeId', this.currentLernspot?.slides[this.currentSlide]?.pfadaufgabe?.data?.id);
      }
      if (typ === 'MC_Frage') {
        this.$store.commit('setSelectedPfadaufgabeId', null);
        this.$store.commit('setSelectedMcFrageId', this.currentLernspot?.slides[this.currentSlide]?.mc_frage?.data?.id);
      }
    },

    openOverview() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'slideOverview');
    },

    success() {
      this.result = 'success';
      this.iosVibrate(3);
    },

    failure() {
      this.result = 'fail';
      if (this.taskType !== 'ComponentAufgabenMcFrage') this.trials += 1;
      this.iosVibrate(5);
    },

    generatePlaceholderAnswerStructure() {
      this.selectedAnswers = [];
      // eslint-disable-next-line no-underscore-dangle
      if (this.currentLernspot?.slides[this.currentSlide]?.Typ === 'Pfadaufgabe' && this.currentPfadaufgabe?.Aufgabentyp[0].__typename === 'ComponentAufgabenLueckentext') {
        for (let i = 0; i < this.maxAnswers; i += 1) {
          this.selectedAnswers.push('OPTION');
        }
      }
    },

    preCheckAnswer() {
      if (this.contentType === 'Pfadaufgabe' || this.contentType === 'MC_Frage') {
        this.checkAnswer();
      }
    },

    checkAnswer() {
      if (this.taskType === 'ComponentAufgabenBegriffspaare') this.checkBegriffspaare();
      if (this.taskType === 'ComponentAufgabenListenzuordnung') this.checkListenzuordnung();
      if (this.taskType === 'ComponentAufgabenVokabel') this.checkVokabel();
      if (this.taskType === 'ComponentAufgabenMcFrage' || this.contentType === 'MC_Frage') this.checkMcFrage();
      if (this.taskType === 'ComponentAufgabenLueckentext') this.checkLueckentext();
    },

    checkMcFrage() {
      this.isChecking = true;
      const questionId = this.currentMcFrageId;
      this.validateAnswers({ questionId }).then((result) => {
        const correctAnswers = result.data.validateAnswers.correctIds;
        const { selectedAnswers } = this;
        if (correctAnswers.sort().toString() === selectedAnswers.sort().toString()) {
          this.success();
          this.isChecking = false;
        } else {
          this.failure();
          this.isChecking = false;
        }
      });
    },

    checkBegriffspaare() {
      this.validatePairMatch({
        answers: this.selectedAnswers,
        validate: this.currentPfadaufgabe.Aufgabentyp[0].Paar,
      });
    },

    checkListenzuordnung() {
      const answers = [];
      const validate = [];
      this.selectedListAnswers.forEach((listAnswer) => {
        if (listAnswer.list === 1) {
          answers.push(listAnswer.answer);
        }
      });
      answers.sort();
      const validateString = this.currentPfadaufgabe.Aufgabentyp[0].Liste[0].Answers;
      validateString.split(';').sort().forEach((item) => {
        validate.push(item);
      });
      this.validateStringMatch({
        answers: answers.toString().trim(),
        validate: validate.toString().trim(),
      });
    },

    checkVokabel() {
      this.validateVokabelMatch({
        answers: this.selectedAnswers.toString().replace(/,/g, ';').toLowerCase(),
        validate: this.currentPfadaufgabe.Aufgabentyp[0].answer.toLowerCase(),
      });
    },

    checkLueckentext() {
      const validate = [];
      const splittedAnswers = this.currentPfadaufgabe.Aufgabentyp[0].content.split('</s>');
      splittedAnswers.pop();
      splittedAnswers.forEach((answer) => {
        const splitted = answer.split('<s>').pop();
        const parsed = new DOMParser().parseFromString(splitted, 'text/html');
        validate.push(parsed.documentElement.textContent);
      });
      this.validateStringMatch({ answers: this.selectedAnswers.toString(), validate: validate.toString() });
    },

    cancelLernspot() {
      this.iosVibrate(1);
      // this.$store.commit('toggleInfoOverlay', { value: true, index: 1 });
      this.confirmCancelLernspot();
    },

    nextSlide() {
      if (!this.savingProgress) {
        this.$store.commit('setSaveIndicator');
        this.iosVibrate(0);
        console.log('saving');
        console.log(this.currentSlide);
        console.log(this.currentLernspot.slides.length);
        console.log(this.currentSlideProgress.length);
        if (this.currentSlide < this.currentLernspot.slides.length - 1) {
          // save and go to next slide
          this.$store.dispatch('saveProgress', { slideId: this.currentSlideId, currentMaxSlideId: this.nextSlideId, finished: false });
          this.currentSlide += 1;
          window.history.pushState({ currentSlide: this.currentSlide }, '', window.location.href);
          this.result = null;
          this.selectedAnswers = [];
          this.selectedListAnswers = [];
          this.leftAnswers = [];
          this.solvedAnswers = 0;
          this.$store.commit('setSelectedPlaceholder', 0);
          this.scrollToTop();
        } else if (this.currentSlide === this.currentLernspot.slides.length - 1) {
          this.$store.commit('toggleInfoOverlay', { value: true, index: 2 });
          this.$store.dispatch('saveProgress', { slideId: this.currentSlideId, finished: true });
        } // else {
        // this.$store.commit('toggleInfoOverlay', { value: true, index: 3 });
        // this.$store.dispatch('saveProgress', { slideId: this.currentSlideId, finished: false });
        // }
      } else {
        console.log('still saving');
      }
    },

    skipSlide() {
      this.refresh();
      this.currentSlide += 1;
      this.scrollToTop();
    },

    previousSlide() {
      this.refresh();
      this.currentSlide -= 1;
      this.scrollToTop();
    },

    confirmFinishLernspot() {
      this.$store.commit('toggleInfoOverlay', { value: false, level: 1 });
      if (this.mobile) {
        this.$router.push({ name: 'lernpfadMobile' });
      } else {
        this.$router.push({ name: 'learning' });
      }
    },

    confirmCancelLernspot() {
      this.$store.commit('toggleInfoOverlay', { value: false, level: 1 });
      if (this.mobile) {
        this.$router.push({ name: 'lernpfadMobile' });
      } else {
        this.$router.push({ name: 'learning' });
      }
    },

    changeSlide(index) {
      this.refresh();
      this.$store.commit('showOverlay', null);
      this.currentSlide = index;
    },
  },
};
</script>

<style>
.hyphen {
    -webkit-hyphens: auto; /* for Safari */
    hyphens: auto;
}
</style>

